const baseUrlPayments = "api/nodeapp/subscription";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

export const fetchUpcomingSubscriptionEnd = async ({page, limit}) => {
    const url = `${baseUrlPayments}?page=${page}&limit=${limit}`;
  
    try {
      const response = await fetch(url, { method: "GET", headers: getHeaders() });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      return { status: true, data: data };
    } catch (error) {
      console.error("Error fetching payments:", error);
      throw error;
    }
  };