// utils/dateUtils.js
export const getDateDifference = (endDate) => {
    const currentDate = new Date();
    const subscriptionEndDate = new Date(endDate);
  
    // Calculate the difference in milliseconds
    const diffInMs = subscriptionEndDate - currentDate;
  
    // Convert to days, hours, and minutes
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffInMinutes = Math.floor((diffInMs % (1000 * 60 * 60)) / (1000 * 60));
  
    // Return the formatted result
    if (diffInMs > 0) {
      return `${diffInDays}d ${diffInHours}h ${diffInMinutes}m`;
    } else {
      return "Expired";
    }
  };
  