import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const ProtectedRoute = ({ element: Component, allowedRoles = [], ...rest }) => {
  const { user } = useUser();

  // If user is not logged in, redirect to login
  if (!user) {
    return <Navigate to="/login" />;
  }

  // If user's role is not in allowedRoles, restrict access
  if (!allowedRoles.includes(user.role)) {
    return <Navigate to="/login" />;
  }

  // If all checks pass, render the component
  return <Component {...rest} />;
};

export default ProtectedRoute;
