const baseUrl = "api/nodeapp/answers";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

export const fetchAllAnsByLeadId = async (id) => {
  const url = `${baseUrl}/lead/${id}`;

  try {
    const response = await fetch(url, { method: "GET", headers: getHeaders() });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return { status: true, data: data };
  } catch (error) {
    return {status :false, error: error};
  }
};

export const updateAnswersBulk = async (answers) => {
  const url = `${baseUrl}/bulk`;

  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify({ answers }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return { status: true, data: data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};