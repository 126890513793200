import React, { useContext } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";

export default function TemporaryDrawer() {
  const { user } = useContext(UserContext);

  // Define the list of menu items with roles
  const menuItems = [
    { to: `/${user?.role}Dashboard`, label: "Dashboard", roles: ["admin", "manager", "user"] },
    { to: "/users", label: "User", roles: ["admin"] },
    { to: "/viewAll", label: "WhatsNew", roles: ["manager", "admin"] },
    { to: "/payments", label: "Pending Payments", roles: ["admin", "manager", "user"] },
    { to: "/subscriptions", label: "Subscription Expires", roles: ["admin", "manager", "user"] },
    { to: "/payments-leads", label: "My Payment Leads", roles: ["admin", "manager", "user"] },
    { to: "/subscriptions-leads", label: "My Subscription Leads", roles: ["admin", "manager", "user"] },
    { to: "/login", label: "Logout", roles: ["admin", "manager", "user"] },
  ];

  // Utility function to render a ListItem
  const renderListItem = (to, label) => (
    <ListItem disablePadding key={to}>
      <ListItemButton component={Link} to={to}>
        <ListItemIcon>
          <RadioButtonCheckedIcon />
        </ListItemIcon>
        <ListItemText primary={label} />
      </ListItemButton>
    </ListItem>
  );

  // Filter menu items based on user role
  const filteredMenuItems = menuItems.filter((item) => item.roles.includes(user?.role || "guest"));

  return (
    <Box sx={{ width: 250, borderRight: "1px solid black" }} role="presentation">
      <Typography variant="h6" sx={{ padding: 2 }}>
        {`Hello ${user?.userName || "Guest"}`}
      </Typography>
      <Divider />
      <List>
        {filteredMenuItems.map((item) => renderListItem(item.to, item.label))}
      </List>
    </Box>
  );
}
