import React, { useEffect, useState } from "react";
import { Typography, Box, Card, CardContent } from "@mui/material";
import { fetchReminderTransactions } from "../../api/leads";
import { useNavigate } from "react-router-dom";

const UserDashboardPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [reminderData, setReminderData] = useState([]);

  const navigate = useNavigate();

  const getReminders = async () => {
    const data = await fetchReminderTransactions();
    if (data.status) {
      setReminderData(data.data);
      console.log(data.data);
    } else {
      setError("Failed to fetch reminders");
    }
    setLoading(false);
  };

  useEffect(() => {
    getReminders();
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome to the User Dashboard!
      </Typography>
      <Typography variant="h6" gutterBottom>
        Today's Follow-Ups
      </Typography>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: 2,
            overflowX: "auto",
            padding: 2,
            "&::-webkit-scrollbar": { display: "none" },
          }}
        >
          {reminderData.map((reminder, index) => (
            <Card
              key={index}
              sx={{
                minWidth: 200,
                padding: 2,
                flex: "0 0 auto",
                boxShadow: 3,
              }}
              onClick = { () => navigate(`/payment-lead?id=${reminder._id}`)
              }
            >
              <CardContent>
                <Typography variant="h6">{reminder.customerName}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {reminder.customerEmail}
                </Typography>
                <Typography variant="body1">{reminder.customerPhone}</Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default UserDashboardPage;
