import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  TextField,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { createEntry, updateEntry } from "../api/whatsNewApi";
import CustomSnackbarAlert from "./customAlert";
import WhatsNewModel from "../models/WhatsNewModel";

const WhatsNewFormDialog = ({
  open,
  onClose,
  createOrUpdate,
  entry,
  onSuccess,
}) => {
  const [editData, setEditData] = useState({
    type: "web",
    title: "",
    description: "",
    featureDate: "",
    webRout: "",
    mobRout: "",
    mobFile: null,
    webFile: null,
  });

  const [errors, setErrors] = useState({
    title: "",
    description: "",
    webRout: "",
    mobRout: "",
    featureDate: "",
    mobFile: "",
    webFile: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (createOrUpdate === "update" && entry) {
      setEditData({
        type: entry.type,
        title: entry.title,
        description: entry.description,
        featureDate: entry.featureDate,
        webRout: entry.webRout,
        mobRout: entry.mobRout,
        mobFile: null,
        webFile: null,
      });
    } else {
      setEditData({
        type: "web",
        title: "",
        description: "",
        featureDate: "",
        webRout: "",
        mobRout: "",
        mobFile: null,
        webFile: null,
      });
    }
  }, [createOrUpdate, entry]);

  const validateForm = () => {
    let isValid = true;
    const formErrors = { ...errors };
  
    // Validate title
    if (!editData.title) {
      formErrors.title = "Title is required";
      isValid = false;
    } else {
      formErrors.title = "";
    }
  
    // Validate description
    if (!editData.description) {
      formErrors.description = "Description is required";
      isValid = false;
    } else {
      formErrors.description = "";
    }
  
    // Validate feature date
    if (!editData.featureDate) {
      formErrors.featureDate = "Feature Date is required";
      isValid = false;
    } else {
      formErrors.featureDate = "";
    }
  
    // Skip file and type validation for "update"
    if (createOrUpdate === "create") {
      // Validate files and type for "create" only
      if (editData.type === "mob" && !editData.mobFile) {
        formErrors.mobFile = "Mobile file is required";
        isValid = false;
      } else {
        formErrors.mobFile = "";
      }
  
      if (editData.type === "web" && !editData.webFile) {
        formErrors.webFile = "Web file is required";
        isValid = false;
      } else {
        formErrors.webFile = "";
      }
  
      if (editData.type === "both") {
        if (!editData.mobFile) {
          formErrors.mobFile = "Mobile file is required for type 'both'";
          isValid = false;
        } else {
          formErrors.mobFile = "";
        }
  
        if (!editData.webFile) {
          formErrors.webFile = "Web file is required for type 'both'";
          isValid = false;
        } else {
          formErrors.webFile = "";
        }
      }
  
      // Validate type for "create" only
      if (!editData.type) {
        formErrors.type = "Type is required";
        isValid = false;
      } else {
        formErrors.type = "";
      }
    } else {
      // Clear file and type errors for "update"
      formErrors.mobFile = "";
      formErrors.webFile = "";
      formErrors.type = "";
    }
  
    setErrors(formErrors);
    return isValid;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    try {
      let result;
      if (createOrUpdate === "create") {
        const whatsNewModel = new WhatsNewModel({
          title: editData.title,
          description: editData.description,
          featureDate: editData.featureDate,
          type: editData.type,
          mobRout: editData.mobRout,
          webRout: editData.webRout,
        });
        if (editData.mobFile) whatsNewModel.mobFile = editData.mobFile;
        if (editData.webFile) whatsNewModel.webFile = editData.webFile;

        result = await createEntry(whatsNewModel);
      } else if (createOrUpdate === "update" && entry) {
        const updateData = {
          title: editData.title,
          description: editData.description,
          featureDate: editData.featureDate,
          mobRout: editData.mobRout,
          webRout: editData.webRout
        };
        result = await updateEntry(entry._id, updateData);
      }

      if (result.success) {
        onSuccess(
          createOrUpdate === "create"
            ? "Created successfully."
            : "Updated successfully."
        );
      } else {
        setAlertSeverity("error");
        setAlertMessage(result.error);
        setShowAlert(true);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("Something went wrong. Please try again.");
      setShowAlert(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {createOrUpdate === "create" ? "Create Item" : "Edit Item"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Title"
              fullWidth
              margin="normal"
              required
              value={editData.title}
              onChange={(e) =>
                setEditData((prev) => ({ ...prev, title: e.target.value }))
              }
              error={!!errors.title}
              helperText={errors.title}
            />
            <TextField
              label="Description"
              fullWidth
              margin="normal"
              required
              value={editData.description}
              onChange={(e) =>
                setEditData((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
              error={!!errors.description}
              helperText={errors.description}
            />
            {(editData.type === "mob" || editData.type === "both") && (
              <TextField
                label="Mob Rout"
                fullWidth
                margin="normal"
                required
                value={editData.mobRout}
                onChange={(e) =>
                  setEditData((prev) => ({
                    ...prev,
                    mobRout: e.target.value,
                  }))
                }
                error={!!errors.mobRout}
                helperText={errors.mobRout}
              />
            )}
            {(editData.type === "web" || editData.type === "both") && (
              <TextField
                label="Web Rout"
                fullWidth
                margin="normal"
                required
                value={editData.webRout}
                onChange={(e) =>
                  setEditData((prev) => ({
                    ...prev,
                    webRout: e.target.value,
                  }))
                }
                error={!!errors.webRout}
                helperText={errors.webRout}
              />
            )}
            {createOrUpdate !== "update" && (
              <FormControl fullWidth margin="normal">
                <InputLabel>Type</InputLabel>
                <Select
                  value={editData.type}
                  onChange={(e) =>
                    setEditData((prev) => ({ ...prev, type: e.target.value }))
                  }
                >
                  <MenuItem value="mob">Mobile</MenuItem>
                  <MenuItem value="web">Web</MenuItem>
                  <MenuItem value="both">Mob/Web</MenuItem>
                </Select>
              </FormControl>
            )}
            <TextField
              label="Feature Date"
              type="date"
              fullWidth
              margin="normal"
              required
              InputLabelProps={{ shrink: true }}
              value={editData.featureDate}
              onChange={(e) =>
                setEditData((prev) => ({
                  ...prev,
                  featureDate: e.target.value,
                }))
              }
              error={!!errors.featureDate}
              helperText={errors.featureDate}
            />
            {(editData.type === "mob" && createOrUpdate === 'create') && (
              <div>
                <input
                  type="file"
                  onChange={(e) =>
                    setEditData((prev) => ({
                      ...prev,
                      mobFile: e.target.files[0],
                    }))
                  }
                />
              </div>
            )}
            {(editData.type === "web" && createOrUpdate === 'create') && (
              <div>
                <input
                  type="file"
                  onChange={(e) =>
                    setEditData((prev) => ({
                      ...prev,
                      webFile: e.target.files[0],
                    }))
                  }
                />
              </div>
            )}
            {(editData.type === "both" && createOrUpdate === 'create') && (
              <div>
                <p>Upload Mobile File</p>
                <input
                  type="file"
                  onChange={(e) =>
                    setEditData((prev) => ({
                      ...prev,
                      mobFile: e.target.files[0],
                    }))
                  }
                />
                <p>Upload Web File</p>
                <input
                  type="file"
                  onChange={(e) =>
                    setEditData((prev) => ({
                      ...prev,
                      webFile: e.target.files[0],
                    }))
                  }
                />
              </div>
            )}
            <Box marginTop={2}>
              <Button type="submit" variant="contained" color="primary">
                {createOrUpdate === "create" ? "Create" : "Update"}
              </Button>
              <Button
                onClick={onClose}
                variant="outlined"
                color="secondary"
                style={{ marginLeft: "10px" }}
              >
                Close
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <CustomSnackbarAlert
        message={alertMessage}
        severity={alertSeverity}
        open={showAlert}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default WhatsNewFormDialog;
