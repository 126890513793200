import React, { useState, useContext, useEffect } from "react";
import { getMySubscriptionLeads } from "../api/subscriptionLead";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Tooltip,
  TablePagination,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import NotesIcon from "@mui/icons-material/Notes";

export default function MySubscriptionLeads() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leadsData, setLeadsData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
  });
  const [sortConfig, setSortConfig] = useState({
    key: "customerName",
    direction: "asc",
  });

  const navigate = useNavigate();

  const getMyLeads = async () => {
    setLoading(true);
    try {
      const data = await getMySubscriptionLeads({
        page: page + 1,
        limit: rowsPerPage,
      });
      if (data.status) {
        setLeadsData(data.data);
        setPagination(data.data.pagination);
      }
    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const isSameKey = prevConfig.key === key;
      const newDirection =
        isSameKey && prevConfig.direction === "asc" ? "desc" : "asc";
      return { key, direction: newDirection };
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "warning"; // Yellow
      case "contacted":
        return "info"; // Blue
      case "follow":
        return "primary"; // Light Blue
      case "completed":
        return "success"; // Green
      case "denied":
        return "error"; // Red
      default:
        return "default"; // Grey
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedLeads = leadsData?.myLeads
    ? [...leadsData.myLeads].sort((a, b) => {
        if (!sortConfig.key) return 0;
        var aValue;
        var bValue;

        aValue = a[sortConfig.key] || "";
        bValue = b[sortConfig.key] || "";

        if (
          sortConfig.key == "phone" ||
          sortConfig.key == "email" ||
          sortConfig.key == "name"
        ) {
          aValue = a.subscription[sortConfig.key] || "";
          bValue = b.subscription[sortConfig.key] || "";
        } else {
          aValue = a[sortConfig.key] || "";
          bValue = b[sortConfig.key] || "";
        }

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        }

        const aStr = String(aValue).toLowerCase();
        const bStr = String(bValue).toLowerCase();

        if (aStr < bStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aStr > bStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      })
    : [];

  useEffect(() => {
    getMyLeads();
  }, [page, rowsPerPage]);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h4"
        sx={{ marginBottom: 3, fontWeight: "bold", color: "primary.main" }}
      >
        Subscription Expiries Leads({pagination.totalCount} Total)
      </Typography>
      <Box
        sx={{
          overflow: "auto",
          marginBottom: 3,
          boxShadow: 2,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.300",
        }}
      >
        <TableContainer component={Paper} sx={{ borderRadius: "8px" }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { key: "name", label: "Name" },
                  { key: "email", label: "Email" },
                  { key: "phone", label: "Phone" },
                  { key: "createdAt", label: "Pick Date" },
                  { key: "notes", label: "Note" },
                  { key: "status", label: "Lead Status" },
                ].map((column) => (
                  <TableCell
                    key={column.key}
                    onClick={() => handleSort(column.key)}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      // textAlign: "center",
                      "&:hover": { backgroundColor: "primary.dark" },
                    }}
                  >
                    {column.label}{" "}
                    {sortConfig.key === column.key &&
                      (sortConfig.direction === "asc" ? (
                        <KeyboardArrowUpIcon sx={{ verticalAlign: "middle" }} />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ verticalAlign: "middle" }}
                        />
                      ))}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedLeads.map((entry, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": { backgroundColor: "grey.100" },
                    "&:hover": { backgroundColor: "grey.200" },
                  }}
                  onClick={() => navigate(`/subscription-lead?id=${entry._id}`)}
                >
                  <TableCell>{entry.subscription.name}</TableCell>
                  <TableCell>{entry.subscription.email}</TableCell>
                  <TableCell>{entry.subscription.phone}</TableCell>
                  <TableCell>
                    {`${new Date(
                      entry.createdAt
                    ).toLocaleDateString()} ${new Date(
                      entry.createdAt
                    ).toLocaleTimeString()}`}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={entry.notes ?? "Nothing"} arrow>
                      <NotesIcon />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        entry.status.charAt(0).toUpperCase() +
                        entry.status.slice(1)
                      }
                      color={getStatusColor(entry.status)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={pagination.totalCount}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 20, 50]}
        sx={{
          borderTop: "1px solid",
          borderColor: "grey.300",
          paddingTop: 2,
        }}
      />
    </Box>
  );
}
