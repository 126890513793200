const baseUrl = "api/nodeapp/subscription-leads";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

// 1. Create a new lead
export const pickSubscriptionLead = async (subscription, asignedTo, followUpDate) => {
  try {
    const response = await fetch(`${baseUrl}`, {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ subscription, asignedTo, followUpDate }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 2. Get all leads
export const getAllSubscriptionLeads = async () => {
  try {
    const response = await fetch(`${baseUrl}`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 3. Get today's reminder leads
export const getTodayReminderLeads = async () => {
  try {
    const response = await fetch(`${baseUrl}/today-reminders`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 4. Get leads assigned to the logged-in user
export const getMySubscriptionLeads = async ({page, limit}) => {
  try {
    const response = await fetch(`${baseUrl}/my-leads?page=${page}&limit=${limit}`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 5. Get a single lead by ID
export const getSubscriptionLeadById = async (leadId) => {
  try {
    const response = await fetch(`${baseUrl}/${leadId}`, {
      method: "GET",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 6. Update a lead
export const updateSubscriptionLead = async (leadId, updatedData) => {
  try {
    const response = await fetch(`${baseUrl}/${leadId}`, {
      method: "PUT",
      headers: getHeaders(),
      body: JSON.stringify(updatedData),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

// 7. Delete a lead
export const deleteSubscriptionLead = async (leadId) => {
  try {
    const response = await fetch(`${baseUrl}/${leadId}`, {
      method: "DELETE",
      headers: getHeaders(),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return { status: false, error: errorData.error };
    }

    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};
