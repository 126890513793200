const baseUrl = "api/nodeapp/questioins";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

export const getAllQuestions = async () => {
  const url = `${baseUrl}`;
  try {
    const response = await fetch(url, { method: "GET", headers: getHeaders() });
    if (!response.ok) {
      return { status: false, error: "Error" };
    }
    const data = await response.json();
    return { status: true, data: data };
  } catch (error) {
    return { status: false, error: error };
  }
};
