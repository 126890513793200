
function getHeaders() {
  const token = localStorage.getItem("token");

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };

  return headers;
}

export const login = async (username, password) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
  
    const raw = JSON.stringify({
      email: username,
      password: password,
    });
  
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      headers: getHeaders(),
      redirect: "follow",
    };
  
    try {
      const response = await fetch(`api/nodeapp/auth/login`, requestOptions);

      const result = await response.json();
  
      if (response.ok) {
        localStorage.setItem("token", result.token);
  
        return { success: true, token: result.token};
      } 
      else if(response.status === 500){
        return { success: false, error: "Internal ERROR"};
      }
      else {
        return {
          success: false,
          error: `${result.message}`,
        };
      }
    } catch (error) {
      throw error;
    }
  };