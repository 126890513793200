import "./App.css";
import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import CustomThemeProvider from "./components/Theme";
import TemporaryDrawer from "./components/sidebar";
import ButtonAppBar from "./components/commonAppbar";
import LoginTopBar from "./components/loginAppbar";
import AppRoutes from "./routes/AppRoutes";
import { UserProvider } from "./context/UserContext";

function AppContent() {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/";

  return (
    <Box sx={{ display: "flex", height: "100vh" }}>
      {!isLoginPage && (
        <TemporaryDrawer sx={{ height: "100vh", position: "fixed" }} />
      )}

      <Box
        sx={{
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isLoginPage ? <LoginTopBar /> : <ButtonAppBar />}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              mt: 7,
              overflowY: "auto",
              height: "calc(100vh - 64px)",
            }}
          >
            <AppRoutes />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default function App() {
  return (
    <Router>
      <UserProvider>
        <CustomThemeProvider>
          <AppContent />
        </CustomThemeProvider>
      </UserProvider>
    </Router>
  );
}
