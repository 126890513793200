import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { fetchWhatsNew, deleteEntry, updateImage } from "../../api/whatsNewApi";
import WhatsNewFormDialog from "../../components/WhatsNewFormDialog";
import EditIcon from "@mui/icons-material/Edit";
import CustomSnackbarAlert from "../../components/customAlert";
import { useNavigate } from "react-router-dom";

const WhatsNewCardView = () => {
  const [whatsNewData, setWhatsNewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [createOrUpdate, setCreateOrUpdate] = useState("create");
  const [selectedEntry, setSelectedEntry] = useState(null);

  // for Alet
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);
  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    try {
      const data = await fetchWhatsNew();
      setWhatsNewData(data);
    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  // Fetch data initially when the component mounts
  useEffect(() => {
    getData();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  const handleSuccess = (mes) => {
    console.log(mes);
    setOpenDialog(false);
    setAlertMessage(mes);
    setShowAlet(true);
    getData();
  };

  const handleOpenDialogForCreate = () => {
    setCreateOrUpdate("create");
    setSelectedEntry(null);
    setOpenDialog(true);
  };

  const handleOpenDialogForUpdate = (item) => {
    setCreateOrUpdate("update");
    setSelectedEntry(item);
    setOpenDialog(true);
  };

  const handleOnCardClick = (id) => {
    navigate(`/whatsNew?id=${id}`);
  };

  const handleImageChange = async (e, id, type) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result = await updateImage(file, id);
        if (result.success) {
          setAlertMessage("image updated successfully");
          setShowAlet(true);
          getData();
        } else {
          setAlertSeverity("error");
          setAlertMessage(result.error);
          setShowAlet(true);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(error);
        setShowAlet(true);
      }
    }
  };

  const handleDelete = async (e, id) => {
    e.stopPropagation();
    const data = await deleteEntry(id);

    if (data.success) {
      setAlertMessage(data.message);
      setShowAlet(true);
      getData();
      e.preventDefault();
    } else {
      setAlertMessage(data.error);
      setAlertSeverity("error");
      setShowAlet(true);
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">WhatNew ViewAll</Typography>
      <div>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenDialogForCreate}
            sx={{ float: "right" }}
          >
            Create New
          </Button>
        </div>
        <Grid container spacing={3}>
          {whatsNewData.map((item) => (
            <Grid item key={item._id} xs={12} sm={6} md={3}>
              <Card
                sx={{
                  maxWidth: "100%",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => handleOnCardClick(item._id)}
              >
                <CardMedia
                  component="img"
                  height="140"
                  image={item.webImageURL}
                  alt={item.title}
                />
                {/* <Box
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "50%",
                    padding: 1,
                  }}
                >
                  <Tooltip title="Update Image" arrow>
                    <IconButton
                      color="primary"
                      component="label"
                      sx={{ color: "white" }}
                    >
                      <EditIcon />
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleImageChange(e, item._id, 'web')}
                      />
                    </IconButton>
                  </Tooltip>
                </Box> */}
                <CardContent>
                  <Typography variant="h6">{item.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {item.description}
                  </Typography>
                  <Typography
                    variant="caption"
                    display="block"
                    sx={{ marginTop: 1 }}
                  >
                    Feature Date: {item.featureDate}
                  </Typography>
                </CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    p: 2,
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                  >
                    Open
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={(e) => handleDelete(e, item._id)}
                  >
                    Delete
                  </Button>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Entry Form Dialog for Create/Update */}
        <WhatsNewFormDialog
          open={openDialog}
          onClose={handleCloseDialog}
          createOrUpdate={createOrUpdate}
          entry={selectedEntry}
          onSuccess={handleSuccess}
        />
        {/* CustomAlert component */}
        <CustomSnackbarAlert
          message={alertMessage}
          severity={alertSeverity}
          open={showAlert}
          onClose={handleCloseSnackbar}
        />
      </div>
    </Box>
  );
};

export default WhatsNewCardView;
