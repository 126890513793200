// src/pages/DashboardPage.js
import React from "react";
import { Typography, Box } from "@mui/material";

const AdminDashboardPage = () => {
  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4">Welcome to the Admin Dashboard!</Typography>
      <Typography variant="body1">This is your dashboard page.</Typography>
    </Box>
  );
};

export default AdminDashboardPage;
