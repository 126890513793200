import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Box,
} from "@mui/material";
import CustomSnackbarAlert from "./customAlert";
import { createUser, updateUser} from "../api/usersApi";


const UserFormDialog = ({ open, onClose, createOrUpdate, user, onSuccess }) => {
  const [userData, setUserData] = useState({
    email: "",
    userName: "",
    role: "user",
  });

  const [errors, setErrors] = useState({
    email: "",
    userName: "",
    role: "",
  });

  // Snackbar for alerts
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (createOrUpdate === "update" && user) {
      setUserData({
        email: user.email,
        userName: user.userName,
        role: user.role,
      });
    } else {
      setUserData({
        email: "",
        userName: "",
        role: "user",
      });
    }
  }, [createOrUpdate, user]);

  const validateForm = () => {
    let isValid = true;
    let formErrors = { ...errors };

    if (!userData.email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.email)) {
      formErrors.email = "Valid email is required";
      isValid = false;
    } else {
      formErrors.email = "";
    }

    if (!userData.userName) {
      formErrors.userName = "User name is required";
      isValid = false;
    } else {
      formErrors.userName = "";
    }

    if (!userData.role) {
      formErrors.role = "Role is required";
      isValid = false;
    } else {
      formErrors.role = "";
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    try {
      let result;
      let message;
      if (createOrUpdate === "create") {
        message = "User created successfully.";
        result = await createUser(userData);
      } else if (user) {
        message = "User updated successfully.";
        result = await updateUser(user._id, userData);
      }

      if (result.status) {
        onSuccess(message);
      } else {
        setAlertSeverity("error");
        setAlertMessage(result.error || "An error occurred");
        setShowAlert(true);
      }
    } catch (error) {
      setAlertSeverity("error");
      setAlertMessage("An error occurred while processing the request.");
      setShowAlert(true);
    }
  };

  const handleCloseSnackbar = () => {
    setShowAlert(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>
          {createOrUpdate === "create" ? "Create User" : "Edit User"}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <TextField
              id="email"
              label="Email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={userData.email}
              onChange={(e) =>
                setUserData((prev) => ({ ...prev, email: e.target.value }))
              }
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              id="userName"
              label="User Name"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              value={userData.userName}
              onChange={(e) =>
                setUserData((prev) => ({ ...prev, userName: e.target.value }))
              }
              error={!!errors.userName}
              helperText={errors.userName}
            />
            <FormControl fullWidth margin="normal" error={!!errors.role}>
              <InputLabel id="role-label">Role</InputLabel>
              <Select
                labelId="role-label"
                id="role"
                value={userData.role}
                onChange={(e) =>
                  setUserData((prev) => ({ ...prev, role: e.target.value }))
                }
              >
                <MenuItem value="admin">Admin</MenuItem>
                <MenuItem value="manager">Manager</MenuItem>
                <MenuItem value="user">User</MenuItem>
              </Select>
              {errors.role && (
                <p style={{ color: "red", marginTop: "4px" }}>{errors.role}</p>
              )}
            </FormControl>
            <Box
              sx={{
                marginTop: 2,
                display: "flex",
                justifyContent: "space-around",
              }}
            >
              <Button type="submit" variant="contained" color="primary">
                {createOrUpdate === "create" ? "Create" : "Update"}
              </Button>
              <Button onClick={onClose} variant="outlined" color="secondary">
                Cancel
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      {/* Snackbar for Alerts */}
      <CustomSnackbarAlert
        message={alertMessage}
        severity={alertSeverity}
        open={showAlert}
        onClose={handleCloseSnackbar}
      />
    </>
  );
};

export default UserFormDialog;
