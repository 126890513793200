import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchUserProfile } from "../api/usersApi";
import { useNavigate } from "react-router-dom";

// Create the context
export const UserContext = createContext(null);

// Custom hook for easy usage
export const useUser = () => useContext(UserContext);

// Provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const result = await fetchUserProfile((errorMessage) => {
          setError(errorMessage);
          navigate("/login");
        });

        if (result.status) {
          setUser(result.data);
        } else {
          setError(result.error);
        }
      } catch (err) {
        setError(err.message);
        navigate("/login"); // Handle unexpected errors
      } finally {
        setLoading(false);
      }
    };

    initializeUser();
  }, [navigate]);

  if (loading) {
    return <div>Loading...</div>; // Optional loading UI
  }

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
