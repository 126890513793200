import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import CustomSnackbarAlert from "../components/customAlert";
import UserFormDialog from "../components/UserFormDialog";
import {fetchAllUsers, deleteUser} from "../api/usersApi";

export default function Users() {
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [createOrUpdate, setCreateOrUpdate] = useState("create");
  const [selectedUser, setSelectedUser] = useState(null);

  // for Alet
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

 const getAllUsers = async () => {
  setLoading(true);
  try{
    const data = await fetchAllUsers();
    if(data.status){
      setUsers(data.data);
      setLoading(false);
    }
    else setError(data.error);
  }
  catch(e) {
    setError(e);
  }
 }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  const handleSuccess = (mes) => {
    setOpenDialog(false);
    setAlertMessage(mes);
    setShowAlet(true);
    getAllUsers();
  };



  useEffect(() => {
    getAllUsers();
  }, []);

  const handleDeleteUser = async (userId) => {
    const data = await deleteUser(userId);
    if(data.status){
      setAlertMessage(data.message)
      setShowAlet(true);
      getAllUsers();
    }
  };

  const handleOpenDialogForCreateUser = () => {
    setCreateOrUpdate("create");
    setSelectedUser(null);
    setOpenDialog(true);
  };

  const handleOpenDialogForUpdate = (user) => {
    setCreateOrUpdate("update");
    setSelectedUser(user);
    setOpenDialog(true);
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Typography variant="h6" color="error">
        {error}
      </Typography>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Users
        </Typography>
        <div>
          <Button variant="contained" color="primary" sx={{ float: "right" }}
          onClick={() => handleOpenDialogForCreateUser()}
          >
            Create User
          </Button>
        </div>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>User Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Role</strong>
                </TableCell>
                <TableCell>
                  <strong>Actions</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.userName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>{user.role}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      sx={{ marginRight: 1 }}
                      onClick={() => handleOpenDialogForUpdate(user)}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="error"
                      size="small"
                      onClick={() => handleDeleteUser(user._id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <UserFormDialog
        createOrUpdate={createOrUpdate}
        onClose={handleCloseDialog}
        onSuccess={handleSuccess}
        open={openDialog}
        user={selectedUser}
        ></UserFormDialog>
        {/* CustomAlert component */}
        <CustomSnackbarAlert
          message={alertMessage}
          severity={alertSeverity}
          open={showAlert}
          onClose={handleCloseSnackbar}
        />
      </>
    </Box>
  );
}
