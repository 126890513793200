class WhatsNewModel {
    constructor({
      title,
      description,
      mobFile = null,
      webFile = null,
      mobRout = "",
      webRout = "",
      featureDate,
      type,
      active = true,
    }) {
      this.title = title;
      this.description = description;
      this.mobFile = mobFile;
      this.webFile = webFile;
      this.mobRout = mobRout;
      this.webRout = webRout;
      this.featureDate = featureDate;
      this.type = type;
      this.active = active;
    }
  
    toFormData() {
      const formData = new FormData();
      formData.append("title", this.title);
      formData.append("description", this.description);
      if (this.mobFile) formData.append("mobFile", this.mobFile);
      if (this.webFile) formData.append("webFile", this.webFile);
      formData.append("mobRout", this.mobRout);
      formData.append("webRout", this.webRout);
      formData.append("featureDate", this.featureDate);
      formData.append("type", this.type);
      formData.append("active", this.active);
      return formData;
    }
  
    toObject() {
      return {
        title: this.title,
        description: this.description,
        mobFile: this.mobFile,
        webFile: this.webFile,
        mobRout: this.mobRout,
        webRout: this.webRout,
        featureDate: this.featureDate,
        type: this.type,
        active: this.active,
      };
    }
  }
  
  export default WhatsNewModel;
  