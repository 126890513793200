import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const CustomSnackbarAlert = ({ message, duration = 3000, severity, open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
    >
      <Alert severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbarAlert;