import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  TablePagination,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { fetchUpcomingSubscriptionEnd } from "../api/subscription";
import { getDateDifference } from "../utils/dateUtil";
import { pickSubscriptionLead } from "../api/subscriptionLead";
import { useUser } from "../context/UserContext";
import CommonDialog from "../components/commonDialog";
import CustomSnackbarAlert from "../components/customAlert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function SubscriptionExpiry() {
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showdialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [selectedEntry, setSelectedEntry] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalCount: 0,
  });

  const { user } = useUser();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

  const sortedSubscriptions = subscriptionData?.subscriptions
    ? [...subscriptionData.subscriptions].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const aValue = a?.[sortConfig.key] ?? "";
        const bValue = b?.[sortConfig.key] ?? "";

        if (typeof aValue === "number" && typeof bValue === "number") {
          return sortConfig.direction === "asc"
            ? aValue - bValue
            : bValue - aValue;
        }

        const aStr = String(aValue).toLowerCase();
        const bStr = String(bValue).toLowerCase();

        if (aStr < bStr) return sortConfig.direction === "asc" ? -1 : 1;
        if (aStr > bStr) return sortConfig.direction === "asc" ? 1 : -1;
        return 0;
      })
    : [];

  const handleSort = (key) => {
    setSortConfig((prevConfig) => {
      const isSameKey = prevConfig.key === key;
      const newDirection =
        isSameKey && prevConfig.direction === "asc" ? "desc" : "asc";
      return { key, direction: newDirection };
    });
  };

  const loadSubscription = async () => {
    setLoading(true);
    try {
      const result = await fetchUpcomingSubscriptionEnd({
        page: page + 1,
        limit: rowsPerPage,
      });
      if (result.status) {
        setSubscriptionData(result.data);
        setPagination(result.data.pagination);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openDialog = (subscriptionId) => {
    setSelectedEntry(subscriptionId);
    setDialogTitle("Pick Lead");
    setDialogContent("Do you want to pick this Lead");
    setShowDialog(true);
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  const handleAgree = async () => {
    const data = await pickSubscriptionLead(selectedEntry, user._id);
    if (data.status) {
      loadSubscription();
      setAlertMessage("Lead picked successfully");
      setShowAlet(true);
      navigate(`/subscription-lead?id=${data.data._id}`);
    }
    setShowDialog(false);
  };

  useEffect(() => {
    loadSubscription();
  }, [page, rowsPerPage]);

  if (loading) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography
        variant="h4"
        sx={{ marginBottom: 3, fontWeight: "bold", color: "primary.main" }}
      >
        Upcoming Subscription Expiries({pagination.totalCount} Total)
      </Typography>

      {/* Scrollable Table Container */}
      <Box
        sx={{
          height: "65vh",
          overflow: "auto",
          marginBottom: 3,
          boxShadow: 2,
          borderRadius: 2,
          border: "1px solid",
          borderColor: "grey.300",
        }}
      >
        <TableContainer
          component={Paper}
          sx={{ borderRadius: "8px", overflow: "hidden" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {[
                  { key: "name", label: "Name" },
                  { key: "email", label: "Email" },
                  { key: "phone", label: "Phone" },
                  { key: "subscriptionEndDate", label: "Subscription Ends" },
                ].map((column) => (
                  <TableCell
                    key={column.key}
                    onClick={() => handleSort(column.key)}
                    sx={{
                      fontWeight: "bold",
                      backgroundColor: "primary.main",
                      color: "white",
                      cursor: "pointer",
                      // textAlign: "center",
                      "&:hover": { backgroundColor: "primary.dark" },
                      "&:first-of-type": { borderTopLeftRadius: "8px" },
                      "&:last-of-type": { borderTopRightRadius: "8px" },
                    }}
                  >
                    {column.label}{" "}
                    {sortConfig.key === column.key &&
                      (sortConfig.direction === "asc" ? (
                        <KeyboardArrowUpIcon sx={{ verticalAlign: "middle" }} />
                      ) : (
                        <KeyboardArrowDownIcon
                          sx={{ verticalAlign: "middle" }}
                        />
                      ))}
                  </TableCell>
                ))}
                <TableCell
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "primary.main",
                    color: "white",
                    // textAlign: "center",
                    "&:first-of-type": { borderTopLeftRadius: "8px" },
                    "&:last-of-type": { borderTopRightRadius: "8px" },
                  }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortedSubscriptions.map((subscription, index) => (
                <TableRow
                  key={index}
                  sx={{
                    "&:nth-of-type(even)": { backgroundColor: "grey.100" },
                    "&:hover": { backgroundColor: "grey.200" },
                  }}
                >
                  <TableCell>{subscription.name}</TableCell>
                  <TableCell>{subscription.email}</TableCell>
                  <TableCell>{subscription.phone}</TableCell>
                  <TableCell>
                    {getDateDifference(subscription.subscriptionEndDate)}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ textTransform: "none", fontWeight: "bold" }}
                      onClick={() => openDialog(subscription._id)}
                    >
                      Pick
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Table Pagination */}
      <TablePagination
        component="div"
        count={pagination.totalCount}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[5, 10, 20, 50]}
        sx={{
          borderTop: "1px solid",
          borderColor: "grey.300",
          paddingTop: 2,
        }}
      />

      <CommonDialog
        open={showdialog}
        title={dialogTitle}
        content={dialogContent}
        onCancel={handleCancel}
        onAgree={handleAgree}
      />
      <CustomSnackbarAlert
        message={alertMessage}
        severity={alertSeverity}
        open={showAlert}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
}
