import React from "react";
import {useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Card,
  CardMedia,
  Chip,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import WhatsNewFormDialog from "../../components/WhatsNewFormDialog";
import { updateImage , fetchWhatsNewById} from "../../api/whatsNewApi";

export default function WhatsNew() {

  const [searchParams] = useSearchParams();

  const id = searchParams.get("id");

  const [openDialog, setOpenDialog] = useState(false);
  const [whatsNew, setWhatsNew] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // for Alet
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

  const getById = async () => {
    setLoading(true);
    try {
      const data = await fetchWhatsNewById(id);

      if (data.status) {
        setWhatsNew(data.data);
      }

    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

 useEffect(() => {
  getById();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSuccess = (mes) => {
    setOpenDialog(false);
    setAlertMessage(mes);
    setShowAlet(true);
    getById();
  };

  const handleImageChange = async (e, id, type) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result = await updateImage(file, id, type);
        if (result.success) {
          setAlertMessage("image updated successfully");
          setShowAlet(true);
          getById();
        } else {
          setAlertSeverity("error");
          setAlertMessage(result.error);
          setShowAlet(true);
        }
      } catch (error) {
        setAlertSeverity("error");
        setAlertMessage(error);
        setShowAlet(true);
      }
    }
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          padding: 3,
        }}
      >
        {/* Title */}
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" gutterBottom>
            {whatsNew.title}
          </Typography>
          <Chip
            label={whatsNew.active ? "Active" : "InActive"} // Showing type in uppercase
            sx={{
              backgroundColor: whatsNew.active ? "green" : "gray",
              color: "white",
              fontWeight: "bold",
            }}
          />
          <Chip
            label={whatsNew.type.toUpperCase()} // Showing type in uppercase
            sx={{
              backgroundColor:
                whatsNew.type === "web"
                  ? "blue"
                  : whatsNew.type === "mob"
                  ? "green"
                  : "gray", // Different colors for web, mob, or both
              color: "white",
              fontWeight: "bold",
            }}
          />
        </Box>
        {/* Description */}
        <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
          {whatsNew.description}
        </Typography>
        {/* Details */}
        {(whatsNew.type === 'mob' || whatsNew.type === 'both') && <Typography variant="body1" sx={{ marginBottom: 1 }}>
          <strong>Mobile Route:</strong> {whatsNew.mobRout}
        </Typography>}
        {(whatsNew.type === 'web' || whatsNew.type === 'both') && <Typography variant="body1" sx={{ marginBottom: 1 }}>
          <strong>Web Route:</strong> {whatsNew.webRout}
        </Typography>}
        <Typography variant="body1" sx={{ marginBottom: 2 }}>
          <strong>Feature Date:</strong>{" "}
          {new Date(whatsNew.featureDate).toLocaleDateString()}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* WebImage Card*/}
          {(whatsNew.type === "both" || whatsNew.type === "web") && (
            <Card
              sx={{
                maxWidth: 500,
                height: 300,
                position: "relative",
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                image={whatsNew.webImageURL}
                alt="Web Screenshot"
                sx={{
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  padding: 0.5,
                }}
              >
                <Tooltip title="Update Image" arrow>
                  <IconButton
                    color="primary"
                    component="label"
                    sx={{ color: "white" }}
                  >
                    <EditIcon />
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleImageChange(e, whatsNew._id, "web")}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          )}
          {/* Mobile Image Card */}
          {(whatsNew.type === "both" || whatsNew.type === "mob") && (
            <Card
              sx={{
                maxWidth: 150,
                height: 300,
                position: "relative",
                borderRadius: 2,
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                overflow: "hidden",
              }}
            >
              <CardMedia
                component="img"
                height="100%"
                image={whatsNew.mobImageURL}
                alt="Mobile Screenshot"
                sx={{
                  objectFit: "cover",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "50%",
                  padding: 0.5,
                }}
              >
                <Tooltip title="Update Image" arrow>
                  <IconButton
                    color="primary"
                    component="label"
                    sx={{ color: "white" }}
                  >
                    <EditIcon />
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleImageChange(e, whatsNew._id, 'mob')}
                    />
                  </IconButton>
                </Tooltip>
              </Box>
            </Card>
          )}
        </Box>
        {/* Buttons */}
        <Box sx={{ display: "flex", justifyContent: "end", marginTop: 3 }}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setOpenDialog(true);
            }}
          >
            Update Details
          </Button>
        </Box>
      </Box>
      {/* Entry Form Dialog for Create/Update */}
      <WhatsNewFormDialog
        open={openDialog}
        onClose={handleCloseDialog}
        createOrUpdate={"update"}
        entry={whatsNew}
        onSuccess={handleSuccess}
      />
    </>
  );
}
