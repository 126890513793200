const API_URL = "api/nodeapp/users";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

export const fetchAllUsers = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
    redirect: "follow",
  };

  try {
    const response = await fetch(API_URL, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { status: true, data: data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const fetchUserProfile = async (onError) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
    redirect: "follow",
  };

  try {
    const response = await fetch(`${API_URL}/profile`, requestOptions);

    if (!response.ok) {
      throw new Error(`${response.message}`);
    }

    const data = await response.json();

    return { status: true, data: data };
  } catch (error) {
    if (onError) {
      onError(error.message);
    }

    return { status: false, error: error.message };
  }
};


export const createUser = async (userData) => {
  const modUserData = {...userData, password: "12345"};
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(modUserData),
  };

  try {
    const response = await fetch(API_URL, requestOptions);
    const data = await response.json();
    if(response.status === 201){
      return { status: true, data: data };
    }
    else return {status: false, error: data.message}
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const getUser = async (userId) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };

  try {
    const response = await fetch(`${API_URL}/${userId}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const updateUser = async (userId, userData) => {
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  };

  try {
    const response = await fetch(`${API_URL}/${userId}`, requestOptions);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return { status: true, data };
  } catch (error) {
    return { status: false, error: error.message };
  }
};

export const deleteUser = async (userId) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };

  try {
    const response = await fetch(`${API_URL}/${userId}`, requestOptions);
    return { status: true, message: "User deleted successfully" };
  } catch (error) {
    return { status: false, error: error.message };
  }
};