import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { differenceInCalendarDays } from "date-fns";
import {
  Typography,
  Box,
  Divider,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  TablePagination,
} from "@mui/material";
import { getSubscriptionLeadById, updateSubscriptionLead} from "../api/subscriptionLead";
import { getAllQuestions } from "../api/question";
import { fetchAllAnsByLeadId, updateAnswersBulk } from "../api/answer";

export default function SubscriptionLead() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leadsData, setLeadsData] = useState({});
  const [questions, setQuestions] = useState([]);
  const [qadialogOpen, setQaDialogOpen] = useState(false);
  const [updateLeadDialogOpen, setUpdateLeadDialogOpen] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [followUpDate, setFollowUpDate] = useState(null);

  const getData = async () => {
    setLoading(true);
    try {
      const data = await getSubscriptionLeadById(id);
      if (data.status) {
        setLeadsData(data.data);
      }
    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const getQuestions = async () => {
    try {
      const data = await getAllQuestions();
      if (data.status) {
        setQuestions(data.data);
      }
    } catch (error) {
      // handle if failed to fetch qus.
    }
  };

  const getAns = async () => {
    try {
      const data = await fetchAllAnsByLeadId(id);
      if (data.status) {
        setAnswers(data.data);
      }
    } catch (error) {
      // handle if failed to fetch ans.
    }
  };

  useEffect(() => {
    getData();
    getQuestions();
    getAns();
  }, []);

  const handleOpenQaDialog = () => {
    setQaDialogOpen(true);
  };

  const handleCloseQaDialog = () => {
    setQaDialogOpen(false);
  };

  const handleOpenUpdateLeadDialog = () => {
    setUpdateLeadDialogOpen(true);
    setStatus(leadsData.status);
    setNotes(leadsData.notes || "");
    setFollowUpDate(
      leadsData.followUpDate ? leadsData.followUpDate.split("T")[0] : null
    );
  };

  const handleCloseUpdateLeadDialog = () => {
    setUpdateLeadDialogOpen(false);
    setNotes("");
    setStatus("");
    setFollowUpDate(null);
  };

  const handleAnswerChange = (questionId, answer, leadId) => {
    setAnswers((prevAnswers) => {
      // Check if an entry already exists for the questionId
      const existingEntryIndex = prevAnswers.findIndex(
        (ans) => ans.questionId === questionId
      );

      if (existingEntryIndex !== -1) {
        // Update the existing entry with the new answer
        const updatedAnswers = [...prevAnswers];
        updatedAnswers[existingEntryIndex] = {
          ...updatedAnswers[existingEntryIndex],
          answer,
        };
        return updatedAnswers;
      } else {
        return [
          ...prevAnswers,
          {
            answer,
            questionId,
            leadId,
          },
        ];
      }
    });
  };

  const getAnsByQusId = (qusId) => {
    const foundAnswer = answers.find((ans) => ans.questionId === qusId);
    return foundAnswer ? foundAnswer.answer : "";
  };

  const handleQaUpdate = async () => {
    const res = await updateAnswersBulk(answers);
    if (res.status) {
      handleCloseQaDialog();
      getAns();
    }
  };

  const handleLeadUpdate = async () => {
    const updatePayload = {};

    if (status) updatePayload.status = status;
    if (notes) updatePayload.notes = notes;
    if (followUpDate) updatePayload.followUpDate = followUpDate;

    const response = await updateSubscriptionLead(leadsData._id, updatePayload);
    if (response.status) {
      getData();
    }
    handleCloseUpdateLeadDialog();
  };

  const handleLeadDelete = async () => {
    console.log("Lead Delete");
  };

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Subscription Lead Details
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: { xs: "column", md: "row" },
          marginBottom: "20px",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
            padding: 3,
            backgroundColor: "#f9f9f9",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack spacing={2}>
            <Typography>
              <strong>Name:</strong> {leadsData.subscription?.name || "N/A"}
            </Typography>
            <Typography>
              <strong>Email:</strong> {leadsData.subscription?.email || "N/A"}
            </Typography>
            <Typography>
              <strong>Phone:</strong> {leadsData.subscription?.phone || "N/A"}
            </Typography>
          </Stack>
        </Box>

        {/* Right Section */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 3 }}>
          {/* Communication Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Communication</Typography>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Send Email
                </Button>
              </Box>
            </Box>
          </Box>
          {/* Info Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Info</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleOpenQaDialog}
              >
                Use Feedback Form
              </Button>
            </Box>
          </Box>
          {/* Actions Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                Actions
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontStyle: "italic",
                }}
              >
                Last Action Taken:{" "}
                {leadsData.updatedAt
                  ? `${differenceInCalendarDays(
                      new Date(),
                      new Date(leadsData.updatedAt)
                    )} days ago`
                  : "No actions yet"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                <Button
                  onClick={handleOpenUpdateLeadDialog
                  }
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Update
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={handleLeadDelete}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Dialog for Questions and Answers */}
      <Dialog open={qadialogOpen} onClose={handleCloseQaDialog} fullWidth>
        <DialogTitle>Questions and Answers</DialogTitle>
        <DialogContent>
          <Stack spacing={3}>
            {questions.map((question) => (
              <Box
                key={question._id}
                sx={{ padding: 2, border: "1px solid #ccc", borderRadius: 2 }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    fontWeight: 500,
                    marginBottom: 1,
                    color: "primary.main",
                  }}
                >
                  {question.question}
                </Typography>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  variant="outlined"
                  placeholder="Write your answer here..."
                  value={getAnsByQusId(question._id)}
                  onChange={(e) =>
                    handleAnswerChange(
                      question._id,
                      e.target.value,
                      leadsData._id
                    )
                  }
                />
              </Box>
            ))}
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseQaDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleQaUpdate} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog for updating status and notes */}
      <Dialog open={updateLeadDialogOpen} onClose={handleCloseUpdateLeadDialog}>
        <DialogTitle>Update Lead</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
            >
              <MenuItem value="asigned">Asigned</MenuItem>
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="contacted">Contacted</MenuItem>
              <MenuItem value="follow">follow-up</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="denied">Denied</MenuItem>
            </Select>
          </FormControl>
          {status === "follow" && (
            <TextField
              id="reminderDate"
              label="Reminder Date"
              type="date"
              value={followUpDate}
              onChange={(e) => setFollowUpDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <TextField
            label="Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpdateLeadDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleLeadUpdate} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
