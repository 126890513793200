import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { format, differenceInCalendarDays } from "date-fns";
import {
  Typography,
  Box,
  Divider,
  Stack,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  TablePagination,
} from "@mui/material";
import {
  getTransactionsById,
  updateTransaction,
  deleteUserTransactions,
} from "../api/leads";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function Lead() {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leadsData, setLeadsData] = useState({});
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [reminderDate, setReminderDate] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);

  const headers = [
    { label: "Date", key: "date", sortable: true },
    { label: "Amount", key: "amount", sortable: true },
    { label: "Status", key: "status", sortable: false },
  ];

  // Sorting configuration state
  const [sortConfig, setSortConfig] = useState({
    key: "date",
    direction: "desc",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Function to handle sorting
  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    const sortedData = [...leadsData.transactionIds].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });
    setLeadsData({ ...leadsData, transactionIds: sortedData });
  };

  // Pagination logic
  const paginatedData = leadsData?.transactionIds
    ? leadsData.transactionIds.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
      )
    : [];

  const navigate = useNavigate();

  const getData = async () => {
    setLoading(true);
    try {
      const data = await getTransactionsById(id);
      if (data.status) {
        setLeadsData(data.data);
      }
    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const openDialog = () => {
    setStatus(leadsData.status);
    setNotes(leadsData.notes || "");
    setReminderDate(
      leadsData.reminderDate ? leadsData.reminderDate.split("T")[0] : null
    );
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setNotes("");
    setStatus("");
    setReminderDate(null);
  };

  const handleUpdate = async () => {
    const updatePayload = {};

    if (status) updatePayload.status = status;
    if (notes) updatePayload.notes = notes;
    if (reminderDate) updatePayload.reminderDate = reminderDate;
    const response = await updateTransaction(leadsData._id, updatePayload);
    if (response.status) {
      getData();
    }
    closeDialog();
  };

  const handleDelete = async () => {
    const data = await deleteUserTransactions(leadsData._id);

    if (data.status) {
      navigate("/payments-leads", { replace: true });
    } else {
    }
  };

  useEffect(() => {
    getData();
  }, []);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Lead Details
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <Box
        sx={{
          display: "flex",
          gap: 4, // Adds space between left and right sections
          flexDirection: { xs: "column", md: "row" }, // Stacks vertically on smaller screens
          marginBottom: "20px",
        }}
      >
        {/* Left Section */}
        <Box
          sx={{
            flex: 1,
            border: "1px solid #ccc",
            borderRadius: 2,
            padding: 3,
            backgroundColor: "#f9f9f9",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Stack spacing={2}>
            <Typography>
              <strong>Name:</strong> {leadsData.customerName || "N/A"}
            </Typography>
            <Typography>
              <strong>Email:</strong> {leadsData.customerEmail || "N/A"}
            </Typography>
            <Typography>
              <strong>Last Payment Date:</strong>{" "}
              {format(
                new Date(leadsData.transactionIds[0].date),
                "MM/dd/yyyy hh:mm:ss a"
              ) || "N/A"}
            </Typography>
            <Typography>
              <strong>Phone:</strong> {leadsData.customerPhone || "N/A"}
            </Typography>
          </Stack>
        </Box>

        {/* Right Section */}
        <Box sx={{ flex: 1, display: "flex", flexDirection: "column", gap: 3 }}>
          {/* Communication Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Communication</Typography>
            <Box sx={{ display: "flex", gap: 2, flexDirection: "column" }}>
              <Box>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Send Email
                </Button>
              </Box>
            </Box>
          </Box>

          {/* Info Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Typography variant="h6">Info</Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  View Payment Status
                </Button>
              </Box>
            </Box>
          </Box>
          {/* Info Section */}
          <Box
            sx={{
              border: "1px solid #ccc",
              borderRadius: 2,
              padding: 2,
              display: "flex",
              flexDirection: "column",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h6"
                sx={{ color: "primary.main", fontWeight: "bold" }}
              >
                Actions
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{
                  color: "text.secondary",
                  fontStyle: "italic",
                }}
              >
                Last Action Taken:{" "}
                {leadsData.updatedAt
                  ? `${differenceInCalendarDays(
                      new Date(),
                      new Date(leadsData.updatedAt)
                    )} days ago`
                  : "No actions yet"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Box>
                <Button
                  onClick={openDialog}
                  color="primary"
                  variant="contained"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Update
                </Button>
              </Box>
              <Box>
                <Button
                  onClick={handleDelete}
                  variant="outlined"
                  color="secondary"
                  size="small"
                  sx={{ minWidth: "120px" }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Payment History
      </Typography>
      <Divider sx={{ mb: 3 }} />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map(({ label, key, sortable }) => (
                <TableCell
                  key={key}
                  onClick={sortable ? () => handleSort(key) : undefined}
                  sx={{
                    fontWeight: "bold",
                    backgroundColor: "primary.main",
                    color: "white",
                    cursor: "pointer",
                    // textAlign: "center",
                    "&:hover": { backgroundColor: "primary.dark" },
                  }}
                >
                  <strong>{label}</strong>
                  {sortable && sortConfig.key === key && (
                    <IconButton size="small">
                      {sortConfig.direction === "asc" ? (
                        <KeyboardArrowUpIcon />
                      ) : (
                        <KeyboardArrowDownIcon />
                      )}
                    </IconButton>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((entry, index) => (
              <TableRow
                key={index}
                sx={{
                  "&:nth-of-type(even)": { backgroundColor: "grey.100" },
                  "&:hover": { backgroundColor: "grey.200" },
                }}
              >
                <TableCell>
                  {format(new Date(entry.date), "MM/dd/yyyy hh:mm:ss a")}
                </TableCell>
                <TableCell>{entry.amount}</TableCell>
                <TableCell>
                  <Chip
                    label={entry.status === "new" ? "New" : "Old"}
                    color={entry.status === "new" ? "primary" : "default"}
                    size="small"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <TablePagination
        component="div"
        count={leadsData.transactionIds.length}
        page={currentPage - 1}
        onPageChange={(event, newPage) => setCurrentPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 20]}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setCurrentPage(1);
        }}
      />

      {/* Dialog for updating status and notes */}
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogTitle>Update Lead</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Status</InputLabel>
            <Select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
            >
              <MenuItem value="pending">Pending</MenuItem>
              <MenuItem value="contacted">Contacted</MenuItem>
              <MenuItem value="follow">follow-up</MenuItem>Payment History
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="denied">Denied</MenuItem>
            </Select>
          </FormControl>
          {status === "follow" && (
            <TextField
              id="reminderDate"
              label="Reminder Date"
              type="date"
              value={reminderDate}
              onChange={(e) => setReminderDate(e.target.value)}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
          <TextField
            label="Notes"
            multiline
            rows={4}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleUpdate} color="primary" variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
