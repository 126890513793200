import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

const CommonDialog = ({ open, title, content, onCancel, onAgree }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        <div>{content}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary">
          Cancel
        </Button>
        <Button onClick={onAgree} color="primary" variant="contained">
          Agree
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CommonDialog;
